import React from 'react';
import './style.css';
import { isMobile } from 'react-device-detect';
export class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: this.props.canvasProducts
        }
    }

    render() {
        let html = [];
        for (let key in this.props.canvasProducts) {
            let element = this.props.canvasProducts[key];
            for (let category_id in element) {
                let product = element[category_id];
                const styles = {
                    position: 'absolute',
                    left: `${product.position_x}%`, bottom: `${product.position_y}%`,
                    zIndex: product.zindex,
                    width: `${product.width}%`,
                    display: product.image ? 'inherit' : 'none',
                    transform: isMobile ? 'translateY(1px)' : '',
                };
                html.push ([
                    <picture style={styles} key={category_id}>
                        <source srcSet={product.image} media="(max-width: 100%)"/>
                        <img src={product.image} style={{width: '100%'}} alt="product_image"/>
                    </picture>
                 ]);
            }
        }
        return html;
    }

}

export default Products;