import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Container from '@material-ui/core/Container';
import {Image} from 'cloudinary-react';
import {isMobile} from 'react-device-detect';
import './style.css';
import Globals from '../../Globals';
import Skeleton from 'react-loading-skeleton';
import ReactGA from 'react-ga';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
      },
      ImageList: {
        width: 500,
        height: 'auto',
        flexWrap: 'nowrap',
        cursor: 'pointer',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: '#fff',
      },
      titleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
});
export class ScenarioList extends React.Component {

    constructor(props) {
        super(props);
        this.fetchScenarios = this.fetchScenarios.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.addScenarios = this.addScenarios.bind(this);
    }

    componentDidMount() {
        this.fetchScenarios();
    }

    getScenarioImageUrl(scenario_id) {
        return this.state.scenarios[scenario_id].image_url;
    }

    fetchProducts(scenario_id) {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", scenario_id);
        formData.append("category_id", this.props.currentCategoryId);
        const requestUrl = Globals.getRequestUrl('product/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setProducts(data.fetch));
    }

    fetchCategories(scenario_id, _callback) {
        this.props.displayLoader(true);
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", scenario_id);
        formData.append("enabled", 1);
        const requestUrl = Globals.getRequestUrl('category/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setCategoryListByScenario(data.fetch))
            .then(function(){ _callback() });
    }

    setProducts(fetchedProducts) {
        this.props.setProducts(fetchedProducts);
        this.props.displayLoader(false);
    }

    setCategoryListByScenario(fetchedCategories) {
        this.props.addCategoriesList(fetchedCategories);
    }

    fetchScenarios() {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("enabled", 1);
        const requestUrl = Globals.getRequestUrl('scenario/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.addScenarios(data.fetch));
    }

    addScenarios (fetchedScenarios) {
        this.props.addScenarios(fetchedScenarios);
    }

    setScenario (scenario_img, scenario_id) {
        ReactGA.event({category: 'Usuario', action: 'Seleccionó escenario', value: parseInt(scenario_id)});
        this.props.setScenario(scenario_img, scenario_id);
        this.fetchCategories(scenario_id, () => {
            this.fetchProducts(scenario_id);
        });
        if(isMobile) {
            window.scrollTo(0, 0);
        }
    }

    getImagePath(public_id) {
        return Globals.getScenariosCloudPath()+public_id+".jpg"; 
    }

    render() {
        if (!this.props.scenarios || this.props.scenarios.length === 0) {
            return (
                <Skeleton width={'100%'} height={200} style={ {marginTop: '20px' } }/>
            );
        }
        
        const { classes } = this.props;

        return (
                <div className={classes.root}>
                    <Container maxWidth="xl" className="container-scenarios-list">
                        <ListSubheader component="div">Escenarios</ListSubheader>
                        <ImageList className={classes.ImageList + ' scenarios-list'} cols={4.5}>
                            {this.props.scenarios.map((scenario) => (
                            <ImageListItem key={scenario.id} onClick={() => this.setScenario(scenario.image_url, scenario.id)}>
                                    <Image 
                                        cloudName={Globals.getCloudName()} 
                                        publicId={this.getImagePath(scenario.image_public_id)}
                                        width="370"
                                        crop="scale"
                                        className="scenario-thumb"
                                    ></Image>
                                <ImageListItemBar
                                    title={scenario.name}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                />
                            </ImageListItem>
                            ))}
                        </ImageList>
                    </Container>
                </div>
        )
    }
}

export default withStyles(styles)(ScenarioList);