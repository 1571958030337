import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Globals from '../../Globals';
import './style.css';
import ReactGA from 'react-ga';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
});
export class CategorySelect extends React.Component {

    constructor(props) {
        super(props);
        this.fetchCategory = this.fetchCategory.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.addCategories = this.addCategories.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
    }

    componentDidMount() {
        this.fetchCategories();
    }

    fetchCategories() {
        this.props.displayLoader(true);
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", this.props.scenarioId);
        formData.append("enabled", 1);
        const requestUrl = Globals.getRequestUrl('category/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.addCategories(data.fetch));
    }

    fetchCategory(category_id) {
        this.props.displayLoader(true);
        var formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("id", category_id);
        const requestUrl = Globals.getRequestUrl('category/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setCategory(data.fetch));
    }

    fetchProducts(category_id) {
        this.props.displayLoader(true);
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", this.props.scenarioId);
        formData.append("category_id", category_id);
        const requestUrl = Globals.getRequestUrl('product/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setProducts(data));
    }

    setProducts(fetchedProducts) {
        if (fetchedProducts.result === true) {
            this.props.setProducts(fetchedProducts.fetch);
        } else {
            this.props.setProducts([]);
        }
        this.props.displayLoader(false);
    }

    addCategories(fetchedCategories) {
        this.props.addCategories(fetchedCategories);
        this.props.displayLoader(false);
    }

    setCategory(fetchedCategory) {
        this.props.setCategory(fetchedCategory.id, fetchedCategory.name);
        this.props.displayLoader(false);
    }

    handleCategoryChange(event) {
        const category_id = event.target.value;
        ReactGA.event({category: 'Usuario', action: 'Seleccionó categoria', value: parseInt(category_id)});
        this.props.setCategory(category_id);
        this.setState({category_id: category_id});
        this.fetchCategory(category_id);
        this.fetchProducts(category_id);
    }

    render() {
        const { classes } = this.props;
        let categories = [];
        if (this.props.categories) {
            categories = this.props.categories;
        }
        return (
            <div className="category-select-view">
                <FormControl variant="outlined" className={classes.formControl + ' full-width'}>
                    <InputLabel htmlFor="outlined-category-native-simple" id="simple-select-filled-label">Categorías</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            label="Categorías"
                            id= 'outlined-category-native-simple'
                            value={this.props.categoryId}
                            onChange={this.handleCategoryChange}
                        >
                            {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export default withStyles(styles)(CategorySelect);