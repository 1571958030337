import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import Decorator from './components/Decorator/Decorator';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import HttpsRedirect from 'react-https-redirect';
import Globals from './Globals';
import ReactGA from 'react-ga';

// Initialize Google Analytics
ReactGA.initialize(Globals.getGAID());
ReactGA.pageview(window.location.pathname);

require('dotenv').config();

ReactDOM.render(
    <HttpsRedirect>
        <Decorator />
    </HttpsRedirect>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// TODO: change this back once development is finished
serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
