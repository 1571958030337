import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Globals from '../Globals';
import ReactGA from 'react-ga';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d2057d',
    backgroundColor: '#d2057d',
    borderRadius: '0 0 4px 4px',
    marginTop: 14
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    background: '#d2057d',
    color: '#fff',
    '&:first-child': {
        borderBottom: '1px solid #fff',
    },
  },
}))(MenuItem);

export default function CustomizedMenus({anchorEl, handleClose}) {

    const handleClick = (type) => {
      ReactGA.event({category: 'Usuario', action: 'Abrió chat WhatsApp', label: type});
      window.open(Globals.getWhatsAppUrl());
    }

    return (
        <Box boxShadow={5}>
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <StyledMenuItem onClick={() => {handleClick('Distribución')}}>
                <ListItemText primary="PARA DISTRIBUCIÓN" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {handleClick('Personal')}}>
                <ListItemText primary="PARA USO PERSONAL" />
            </StyledMenuItem>
        </StyledMenu>
        </Box>
    );
}
