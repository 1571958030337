export class Globals {

    static getApiToken() {
        return process.env.REACT_APP_QUARELLA_API_TOKEN;
    }
    
    static getRequestUrl(params) {        
        return process.env.REACT_APP_QUARELLA_API_URL + params;
    }

    static getCloudName() {
        return "poncho";
    }

    static getScenariosCloudPath() {
        return "quarella/scenarios/";
    }

    static getProductsCloudPath() {
        return "quarella/products/";
    }

    static getMainSiteUrl() {
        return "https://quarelladecor.com/";
    }

    static getWhatsAppUrl() {
        return "https://wa.link/ct6ndg";
    }

    static getGAID() {
        return "UA-184072545-1";
    }
}

export default Globals;