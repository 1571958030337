import React from 'react';
import './style.css';
import Products from '../Product/Products';
import Skeleton from 'react-loading-skeleton';
import {isMobile} from 'react-device-detect';
export class Scenario extends React.Component {

    render() {
        if (!this.props.scenarioImage) {
            return (
                <Skeleton width={'100%'} height={ isMobile ? '220px' : '100%' } style={ {marginTop: '15px' } }/>
            );
        }
        return (
            <div className="scenario-view" style={ {position: 'relative'} }>
                <picture className="scenario-img" id="scenario-image">
                    <source srcSet={this.props.scenarioImage} media="(max-width: 100%)"/>
                    <img src={this.props.scenarioImage} style={{width: '100%'}} alt="scenario_image" width="1920"/>
                </picture>
                <Products
                    categoryId={this.props.categoryId}
                    productId={this.props.productId}
                    ProductsbyScenario={this.props.handleChangeProductsbyScenario}
                    canvasProducts={this.props.canvasProducts}
                />
            </div>
        );
    }
}

export default Scenario;