import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 1),
    width: isMobile ? 'auto' : '50vw',
    height: isMobile ? '100%' : 'auto',
    maxHeight: isMobile ? 'unset' : '100%',
    overflowY: 'scroll',
  },
  logo: {
      maxWidth: isMobile ? '100%' : '80%',
  },
  head: {
    marginTop: isMobile ? 30 : 0,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  heading: {
    margin: 0,
  },
  step: {
    width: '100%',
  },
  stepNo: {
    color: '#e8038b', 
    fontWeight: 'bold'
  },
  stepHead: { 
    textAlign: 'left', 
    fontSize: '1.2em'
  },
  stepText: {
    textAlign: 'left', 
    marginLeft: '1.4rem', 
    fontSize: '1em',
  },
  startButton: {
    backgroundColor: '#e7028b',
    display: 'block',
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '25px'
  }
}));

export default function TutorialModal({ open, onClose, afterClose }) {

  if (open) {
    ReactGA.modalview('Tutorial');
  }

  const classes = useStyles();

  const handleClose = () => {
    onClose();
    afterClose();
  };

  const path = isMobile ? 'mobile' : 'desktop';

  const steps = [
    {
      step: 1,
      head: "Elige tu escenario",
      text: "Podrás elegir entre 15 distintos escenarios de baño, cocina, lobby o estudio",
    },
    {
      step: 2,
      head: "Selecciona la categoria",
      text: "La encontrarás del lado derecho",
    },
    {
      step: 3,
      head: "Selecciona el producto",
      text: "Puedes hacer varias combinaciones",
    },
    {
      step: 4,
      head: "Más ambientes para decorar",
      text: "En la parte de abajo encontrarás otras opciones de escenarios. Si quieres empezar una nueva decoración solo tienes que elegir uno diferente",
    },
  ];

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
      >
          <Fade in={open}>
            <div className={classes.paper}>
                <Grid container className={classes.head}>
                  <Grid item xs={6} md={3}>
                      <h2 className={classes.heading}>Tutorial</h2>
                  </Grid>
                  <Grid item xs={6} md={3}>
                      <img className={classes.logo} src="/img/logo.png" alt="quarella-logo"/>
                  </Grid>
                </Grid>
                <Grid container spacing={isMobile ? 3 : 0}>
                  {
                    steps.map((step) => { 
                      return (
                        <Grid item xs={12} md={6} key={step.step}>
                          <p className={classes.stepHead}>
                            <span className={classes.stepNo}>{step.step}.</span> {step.head}
                          </p>
                          <p className={classes.stepText}>{step.text}</p>
                          <img className={classes.step} src={`/img/${path}/${step.step}.jpg`} alt="start"/>
                        </Grid>
                      )
                    })
                  }
                </Grid>
                <Button variant="contained" className={classes.startButton} onClick={handleClose}>Iniciar</Button>
            </div>
          </Fade>
      </Modal>
  );
}