import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@material-ui/icons/Help';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TutorialModal from './TutorialModal';
import ContactModal from './ContactModal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StoreIcon from '@material-ui/icons/Store';
import BarSubMenu from './BarSubMenu';
import BarNestedMenu from './BarNestedMenu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#d1047d',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  titleBar: {
      marginLeft: '15px',
  },
   openButton: {
    backgroundColor: '#51bed5',
    display: 'block',
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export default function QuarellaAppBar() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [nestedMenuOpen, setNestedMenuOpen] = React.useState(false);

  const [isTutorialOpen, setTutorialOpen] = React.useState(false);
  
  const openTutorialDialog = () => {
    setTutorialOpen(true);
  };

  const closeTutorialDialog = () => {
    setTutorialOpen(false);
  };

  const [isContactOpen, setContactOpen] = React.useState(false);

  const openContactDialog = () => {
    setContactOpen(true);
  };

  const closeContactDialog = () => {
    setContactOpen(false);
  };

  const openSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSubMenu = () => {
    setAnchorEl(null);
  };

  const handleNestedMenu = () => {
    setNestedMenuOpen(!nestedMenuOpen);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if(event){
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    }
    
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={ {backgroundColor: '#d1047d'} }>
        <Toolbar>
            <Hidden lgUp>
                <IconButton 
                    onClick={toggleDrawer('top', true)}
                    edge="start" 
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon />
                </IconButton>
            </Hidden>
          <Typography variant="h6" className={classes.title}>
            <Avatar src="/icon.png" variant="square"/>
            <span className={classes.titleBar}>Quarella Design</span>
          </Typography>
          <Hidden mdDown>
            <Button color="inherit" onClick={openTutorialDialog}>Tutorial</Button>
            <Button color="inherit">Decorador</Button>
            <Button color="inherit" onClick={openContactDialog}>Contacto</Button>
            <Button color="inherit" onClick={openSubMenu}>Quiero el Producto <ArrowDropDownIcon/></Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden lgUp>
        <Drawer anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
                <div
                    className={clsx(classes.list, {
                        [classes.fullList]: true,
                    })}
                    role="presentation"
                    onKeyDown={toggleDrawer('top', false)}
                >
                    <List>
                        <ListItem button key="tutorial" onClick={openTutorialDialog}>
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tutorial" />
                        </ListItem>
                        <ListItem button key={"decorador"}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Decorador"} />
                        </ListItem>
                        <ListItem button key={"contacto"} onClick={openContactDialog}>
                            <ListItemIcon>
                                <MailOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Contacto"} />
                        </ListItem>
                        <ListItem button  key={"purchase"} onClick={handleNestedMenu}>
                          <ListItemIcon>
                            <StoreIcon />
                          </ListItemIcon>
                          <ListItemText primary="Quiero el Producto" />
                          {nestedMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <BarNestedMenu open={nestedMenuOpen} />
                    </List>
                </div>
            </Drawer>
      </Hidden>

      <BarSubMenu anchorEl={anchorEl} handleClose={closeSubMenu}/>

      <TutorialModal 
        afterClose={toggleDrawer('top', false)}
        open={isTutorialOpen}
        onClose={closeTutorialDialog}
      >
      </TutorialModal>

      <ContactModal
        open={isContactOpen}
        onClose={closeContactDialog}
      >
      </ContactModal>
    </div>
  );
}
