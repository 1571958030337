import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Globals from '../Globals';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function BarNestedMenu({open}) {
  const classes = useStyles();

  const handleClick = (type) => {
    ReactGA.event({category: 'Usuario', action: 'Abrió chat WhatsApp', label: type});
    window.open(Globals.getWhatsAppUrl());
  }

  return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested} onClick={() => {handleClick('Distribución')}}>
            <ListItemIcon>
              <LocalShippingIcon/>
            </ListItemIcon>
            <ListItemText primary="Para distribución" />
          </ListItem>
          <ListItem button className={classes.nested} onClick={() => {handleClick('Personal')}}>
            <ListItemIcon>
              <ShoppingBasketIcon/>
            </ListItemIcon>
            <ListItemText primary="Para uso personal" />
          </ListItem>
        </List>
      </Collapse>
  );
}
