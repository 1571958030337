import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
import Globals from '../Globals';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    overflowY: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    maxWidth: isMobile ? '' : '50%',
  },
  logo: {
      maxWidth: isMobile ? '100%' : '80%',
  },
  head: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  heading: {
    margin: 0,
  },
  step: {
    maxWidth: '100%',
    marginTop: '18px',
  },
  img: {
    width: '100%',
  }, 
  closeButton: {
    backgroundColor: '#e7028b',
    display: 'block',
    color: '#fff',
    fontWeight: 'bold',
    width: '70%',
  },
  webButton: {
    marginBottom: 10,
    backgroundColor: '#51bed5',
    color: '#fff',
    display: 'block',
    fontWeight: 'bold',
    width: '70%',
  },
}));

export default function ContactModal({ open, onClose }) {

  if (open) {
    ReactGA.modalview('Contacto');
  }

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleGoToWebClick = () => {
    ReactGA.event({category: 'Usuario', action: 'Abrió el sitio QuarellaDecor'});
    window.open(siteUrl, "_blank");
  }

  const siteUrl = Globals.getMainSiteUrl();

  return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
            <div className={classes.paper}>
                <Grid container className={classes.head}>
                    {isMobile ? 
                        <img src="/img/contact-mobile.jpg" alt="contact" className={classes.img} /> : 
                        <img src="/img/contact-desktop.jpeg" alt="contact" className={classes.img} />
                    }
                    <Button variant="contained" className={classes.webButton} onClick={handleGoToWebClick}>Visita Nuestra Web</Button>
                    <Button variant="contained" className={classes.closeButton} onClick={handleClose}>Cerrar</Button>
                </Grid>
            </div>
            </Fade>
        </Modal>
  );
}